@keyframes pulse {
  0% {
    transform: scale(1);
    background-color: #357DED; /* Color inicial */
  }
  50% {
    transform: scale(1.1);
    background-color: #ff4081; /* Color durante la animación */
  }
  100% {
    transform: scale(1);
    background-color: #357DED; /* Regresa al color inicial */
  }
}

.pulse-button {
  animation: pulse 1.5s infinite;
}
